
export default {
  data: function () {
    return {
      socialName: [
        'linkedin-link',
        'xing-link',
        'youtube-link',
        'twitter-link',
        'instagram-link'
      ],
      socialIconsSizes: [
        [18, 16],
        [18, 19],
        [22, 15],
        [18, 15],
        [24, 24]
      ]
    }
  },
  props: {
    copyright: {
      type: Object,
      required: true
    },
    socials: {
      type: Array,
      required: true
    }
  }
}
