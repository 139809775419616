
/* import LanguageSwitcher from '~/components/ui/LanguageSwitcher.vue' */
import FooterBottomSocialsBar from '~/components/footer/FooterBottomSocialsBar.vue'
//import FooterNewsletter from '~/components/footer/FooterNewsletter.vue'
import FooterMenuItem from '~/components/footer/FooterMenuItem.vue'
//import { SimpleSVG } from 'vue-simple-svg'
export default {
  data() {
    return {
      svgLoaded: false
    }
  },
  components: {
    /*  LanguageSwitcher, */
    FooterBottomSocialsBar,
    //FooterNewsletter,
    FooterMenuItem
    //SimpleSVG
  },
  // beforeMount() {
  //   this.$store.dispatch('loadFooterNavigation', 'published')
  // },
  computed: {
    logo() {
      return this.$store.state.menus !== undefined &&
        this.$store.state.menus.footer_nav !== undefined &&
        this.$store.state.menus.footer_nav &&
        this.$store.state.menus.footer_nav.logo &&
        this.$store.state.menus.footer_nav.logo[0] &&
        this.$store.state.menus.footer_nav.logo[0].logo
        ? this.$store.state.menus.footer_nav.logo[0].logo
        : null
    },
    languages() {
      return this.$store.state.menus &&
        this.$store.state.menus.footer_nav &&
        this.$store.state.menus.footer_nav.language &&
        this.$store.state.menus.footer_nav.language[0]
        ? this.$store.state.menus.footer_nav.language[0]
        : null
    },
    socials() {
      return this.$store.state.menus &&
        this.$store.state.menus.footer_nav &&
        this.$store.state.menus.footer_nav.Socials
        ? this.$store.state.menus.footer_nav.Socials
        : null
    },
    newsletter() {
      return this.$store.state.menus &&
        this.$store.state.menus.footer_nav &&
        this.$store.state.menus.footer_nav.newsletter &&
        this.$store.state.menus.footer_nav.newsletter[0]
        ? this.$store.state.menus.footer_nav.newsletter[0]
        : null
    },
    copyright() {
      return this.$store.state.menus &&
        this.$store.state.menus.footer_nav &&
        this.$store.state.menus.footer_nav.copyright &&
        this.$store.state.menus.footer_nav.copyright[0]
        ? this.$store.state.menus.footer_nav.copyright[0]
        : null
    },
    servicesandsolutions() {
      return this.$store.state.menus &&
        this.$store.state.menus.footer_nav &&
        this.$store.state.menus.footer_nav.service_and_solutions &&
        this.$store.state.menus.footer_nav.service_and_solutions[0]
        ? this.$store.state.menus.footer_nav.service_and_solutions[0]
        : null
    },
    company() {
      return this.$store.state.menus &&
        this.$store.state.menus.footer_nav &&
        this.$store.state.menus.footer_nav.company &&
        this.$store.state.menus.footer_nav.company[0]
        ? this.$store.state.menus.footer_nav.company[0]
        : null
    },
    productsandtools() {
      return this.$store.state.menus &&
        this.$store.state.menus.footer_nav &&
        this.$store.state.menus.footer_nav.products_and_tools &&
        this.$store.state.menus.footer_nav.products_and_tools[0]
        ? this.$store.state.menus.footer_nav.products_and_tools[0]
        : null
    }
  }
}
